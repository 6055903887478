import React from 'react'
import PageTitle from "./PageTitle";
import bnr5 from "./image/bg3.jpg";
import img1 from './image/project/va_phonix.jfif'
import img2 from './image/project/va_corpus_christi.jpg'
import img3 from './image/project/va_san_antonio.jpg'
import img4 from './image/project/dytona_beach_florida.jpg'
import img5 from "./image/project/varginia_clicnic.jpg";
import img6 from "./image/project/va_cob_county.jpg";

const ProjectData = [
  {
    id: "1",
    image: img1,
    title: "VA Phoenix",
  },
  {
    id: "2",
    image: img2,
    title: "VA Corpus Christi",
  },
  {
    id: "3",
    image: img3,
    title: "VA San Antonio",
  },
  {
    id: "4",
    image: img4,
    title: "Daytona Beach, Florida",
  },
  {
    id: "5",
    image: img5,
    title: "Fredericksburg, Virginia – VA Clinic",
  },
  {
    id: "6",
    image: img6,
    title: "Va Cobb County, GA",
  },
];

const Project = () => {
  return (
    <div className="">
      {/* <!-- inner page banner --> */}
      <div
        className="dlab-bnr-inr overlay-primary h-64 bg-cover bg-no-repeat"
        style={{ backgroundImage: "url(" + bnr5 + ")" }}
      >
        <div className="">
          <PageTitle motherMenu="Projects" activeMenu="Projects" />
        </div>
      </div>
      <div class="flex flex-wrap justify-center my-10 mx-4 sm:mt-12 sm:mb-12  sm:mx-48">
        {ProjectData.map((project) => {
          return (
            <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 p-1">
              <div class="bg-white   overflow-hidden">
                <img
                  src={project.image}
                  alt="Yourdescription"
                  class="w-full h-[400px] object-cover"
                />
                <div class="flex justify-center items-center p-1">
                  <h3 class="text-xl font-small text-center text-gray-600">
                    {project.title}
                  </h3>
                  <div className="w-3 h-3 ml-2 bg-black"></div>
                </div>
              </div>
            </div>
          );
        })}
        <div class="bg-[#e7f3fe] border-l-[6px] border-[#2196F3] mb-4  mt-4 py-2 px-2">
          <p>
            <strong>Photos Courtesy of:</strong> Mike Reigner, DAU
            Concepts, and Carnegie Management and Development Corporation.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Project